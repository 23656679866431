<template>
  <v-container fluid>
    <div>
      <v-container fluid>
        <v-card color="white">
          <v-layout row>
            <v-card flat>
              <v-card-text class="title">Special Form Fillup List</v-card-text>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
          </v-layout>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="formFillUpByAdminList"
          class="elevation-1"
          :total-items="pagination.totalItems"
          :pagination.sync="pagination"
          :loading="loading"
          :rows-per-page-items="pagination.rowsPerPageItems"
        >
          <template v-slot:items="props">
            <td class="text-xs-left">{{ props.item.reg_no }}</td>

            <td class="text-xs-left">{{props.item.student.name}}</td>
            <td class="text-xs-left">{{props.item.student.father_name}}</td>
            <td class="text-xs-left">{{props.item.student.mother_name}}</td>           
            <td class="text-xs-left">{{formatted_date(props.item.student_confirmed_at)}}</td>
            <td class="justify-left  px-0">
              <v-btn v-if="props.item.teacher_confirm_status == 1" small @click="getformFillupDetelsbyID(props.item.id)">Download Application Form</v-btn>
              <v-btn color="red" v-if="props.item.teacher_confirm_status == 1" small @click="cancelConfirmation(props.item)" style="color:white">Cancel Application</v-btn>
            </td>
          </template>
          <template>
            <!-- v-slot:expand="props" -->
            <v-card flat>
              <v-card-text>Peek-a-boo!</v-card-text>
            </v-card>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-container>
    </div>
    <!-- <pre>{{getSchema}}</pre>  -->
    <!-- <pre>{{ formFillUpByAdminList }}</pre> -->
  </v-container>
</template>
<script>
import moment from "moment";
import commonGet from "../../mixin/common_getters_mixin";

export default {
  mixins: [commonGet],
  name:"SpecialFormFillupList",
  components: {
  },

  data: () => ({
    pagination: {
      rowsPerPage: 10,
      rowsPerPageItems: [10]
    },
    totalDataItems: 0,
    loading: true,
    searchParam: {},
    search: "",

    headers: [
      { text: "Registration No", align: "left", value: "reg_no" },
      { text: "Name", align: "left", value: "name" },
      { text: "Father Name", align: "left", value: "father_name" },
      { text: "Mother Name", align: "left", value: "mother_name" },
      { text: "Confirmed Date", align: "left", value: "student_confirmed_at" },
      { text: "Actions", align: "left", sortable: false }
    ],

    dialog: false,
    studentData: "",
    examMaster: {},
    showexamMaster: false,
  }),

  computed: {
    getSchema() {
      return this.$store.getters.getAllExamMaster;
    },
    formFillUpByAdminList(){
      return this.$store.getters.formFillUpByAdminList.data;
    },
    getTadaByIDOfFormFillup() {
      return this.$store.getters.getTadaByIDOfFormFillup;
    },
  },

  created() {
  },
  watch: {
    pagination() {
      this.getDataFromApi();
    },
    getExamInitiateId(val) {
      this.searchParam.exam_id = val;
      if (val) {
        this.getDataFromApi();
      }
    },
    getTadaByIDOfFormFillup() {
      window.open(this.getTadaByIDOfFormFillup);
    },
  },

  methods: {
    getformFillupDetelsbyID(option) {
      this.$store.dispatch("fetchStudentFormFillupDetailsByID", option);
    },
    cancelConfirmation(options){
      this.$store.dispatch("cancelConfirmationAdmin", options).then((response) => {
        this.getDataFromApi();
      })
    },
    setLimit() {
      const { sortBy, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.exam_id = this.getExamInitiateId;
      this.searchParam.search = this.search;
    },

    getDataFromApi() {
      this.loading = true;
      this.searchParam.exam_id = this.getExamInitiateId;
      this.setLimit();
      this.$store
        .dispatch("fetchAllSpecialFormFillup", this.searchParam)
        .then(data => {
          this.loading = false;
          this.pagination.totalItems = data.meta.total;
        });
    },

    formatted_date(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style scoped></style>
